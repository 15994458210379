<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/iotEquipment' }">设备管理</el-breadcrumb-item>
          <el-breadcrumb-item>设备详情</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="table-tree">
        <div class="tree">
          <div class="tree-title"><i class="el-icon-share" style="margin-right:10px;"></i>设备类型</div>
          <el-tree
            :data="treeData"
            node-key="deviceTypeCode"
            @node-click="treeRowClick"
            :props="defaultProps"
            :expand-on-click-node="false"
            highlight-current
            :current-node-key="deviceTypeCode"
            default-expand-all
            ref="tree">
            <span class="custom-tree-node" slot-scope="{ node }">
              <span style="margin-right: 40px"><i class="el-icon-folder-opened"></i> {{ node.label }}</span>
            </span>
          </el-tree>
        </div>
            
        <div class="table">
          <el-table height="240" :data="tableData" style="width: 100%" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
            <el-table-column prop="equipmentName" label="子设备名称"></el-table-column>
            <el-table-column prop="eid" label="子设备号"></el-table-column>
            <el-table-column prop="deviceTypeCode" label="子设备类型">
              <template slot-scope="scope">
                <span v-show="scope.row.deviceTypeCode=='Direct'">直连设备</span>
                <span v-show="scope.row.deviceTypeCode=='GatewaySub'">网关子设备</span>
                <span v-show="scope.row.deviceTypeCode=='Gateway'">网关</span>
              </template>
            </el-table-column>
            <el-table-column prop="equipmentBrandCode" label="子设备品牌"></el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <span v-show="scope.row.status==0">正常</span>
                <span v-show="scope.row.status==1">异常</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="echartLayout">
        <div class="left-list" style="width:30%;height:100%;" v-loading="deviceDataLoading" element-loading-text="数据更新中" element-loading-spinner="el-icon-loading">
          <div><span>设备名称: </span>{{reportData.subEquipmentName?reportData.subEquipmentName:'-'}}</div>
          <div><span>设备号:</span>{{reportData.eid?reportData.eid:'-'}}</div>
          <!-- <div><span>通讯地址:</span>{{reportData.content.equipmentNumber?reportData.content.equipmentNumber:'-'}}</div> -->
          <div><span>瞬时流量:</span>{{reportData.content.flowInstantaneous?reportData.content.flowInstantaneous:'0'}}（m³/h）</div>
          <div><span>瞬时流速:</span>{{reportData.content.fluidVelocity?reportData.content.fluidVelocity:'0'}}（m/s）</div>
          <div><span>正累积流量:</span>{{reportData.content.positiveCumulativeFlow?(reportData.content.positiveCumulativeFlow+reportData.content.positiveCumulativeFlowDecimal):'0'}}（m³）</div>
          <div><span>负累积流量:</span>{{reportData.content.negativeCumulativeFlow?(reportData.content.negativeCumulativeFlow+reportData.content.negativeCumulativeFlowDecimal):'0'}}（m³）</div>
          <div style="border:0;"><span>上报时间:</span>{{reportData.reportTime?dayjs(reportData.reportTime).format('YYYY-MM-DD HH:mm:ss'):'-'}}</div>
        </div>
        <div id="iot-container" style="width:70%; height:100%; overflow:hidden;" v-loading="tableLoading" element-loading-spinner="el-icon-loading"></div>
      </div>
    </div>
    <div class="bottom-btn">
      <el-button type="primary" size="small" @click="$router.push('/iotEquipment')">取消</el-button>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from 'dayjs';
export default {
  data() {
    return {
        loading: false,
        size: 50,
        page: 1,
        total: 0,
        treeData: [
            { deviceTypeCode: 'Gateway',name: '网关' }
        ],
        tableLoading: false,
        tableData: [],
        deviceTypeCode: 'Gateway',
        defaultProps: {
            children: 'childTree',
            label: 'name'
        },
        row: {},
        myChart: null,
        chartData:[],
        chartLink:[],
        equimentData: [],
        reportData: {
            content: {}
        },
        timer: null,
        deviceDataLoading: false
    };
  },
  methods: {
    dayjs,
    initEchart() {
      this.myChart = echarts.init(document.getElementById("iot-container"));
      this.chartData=this.dataEChart();
      this.chartLink=this.linkEChart();
      console.log(this.chartData)
      console.log(this.chartLink)
      let option = {
        tooltip:{//提示框
          show:true,
          formatter: function(params) {
              if(params.data.symbolSize){
                  return '网关设备号：'+params.data.eid
              }else {
                  return '设备名称：'+params.data.subEquipmentName+'<br/>'+
                  '设备号：'+params.data.eid+'<br/>'+
                  '瞬时流量：'+params.data.content.flowInstantaneous+'（m³/h）<br/>'+
                  '瞬时流速：'+params.data.content.fluidVelocity+'（m/s）<br/>'+
                  '正累积流量：'+(params.data.content.positiveCumulativeFlow+params.data.content.positiveCumulativeFlowDecimal)+'（m³）<br/>'+
                  '负累积流量：'+(params.data.content.negativeCumulativeFlow+params.data.content.negativeCumulativeFlowDecimal)+'（m³）<br/>'+
                  '上报时间：'+dayjs(params.data.reportTime).format('YYYY-MM-DD HH:mm:ss')+'<br/>'
              }
          }
        },
        series: [
          {
            type:'graph',//类型：关系图
            layout:'force',
            symbolSize:80,//关系图大小
            //roam:true,//是否可拖拽
            edgeSymbol:'circle',//关系连接点为circle
            edgeSymbolSize: [2, 2],//关系连接点大小
            edgeLabel: {//关系线条上文字
              normal: {
              //   formatter:"{c}",
              //   show:true
              }
            },
            force:{//关系距离
              repulsion:2000
            },
            lineStyle: {//线条样式
              normal: {
                  width: 2,
                  color: '#ccc',
              }
            },
            itemStyle:{//图样式
              normal:{
                color: '#2F4554',
              },
              //鼠标放上去有阴影效果
              emphasis: {
                shadowColor: '#ccc',
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 40,
              },
            },
            label:{//图上文字显示   
              normal:{
                show:true
              }
            },
            //数据
            links: this.chartLink,
            data:this.chartData
          }
        ]
      };
      this.myChart.setOption(option);
      let that = this
      that.myChart.on('click', function (params) {
        // console.log(params.data)//获取点击的头像的数据信息
        if(params.data.symbolSize) {
          that.deviceDataLoading = false
          return
        }
        that.deviceDataLoading = true
        setTimeout(()=>{
          that.reportData = params.data
          that.deviceDataLoading = false
        },500)
      });
    },
    /**
     * 数据集合
     */
    dataEChart(){
        // 网关数据
        let data = [
          {
            id: this.row.id,
            eid: this.row.eid,
            name: this.row.equipmentName,
            symbolSize: 120,
            itemStyle:{
                normal:{
                  color: '#C23531',
                }
            },
          }
        ];

        // 动态数据
        this.equimentData.forEach(v=>{
            v.id = v.eid
            v.name = v.subEquipmentName
        })

        data = data.concat(this.equimentData)
        return data;
    },
    /**
     * 关系数据集合
     */
    linkEChart(){
        let dataLink=[];
        this.equimentData.forEach(v=>{
            dataLink.push({
                source: this.row.eid,
                target: v.id
            })
        })
        return dataLink;
    },
    loadListData(eid) {
      // let flag = 0
      this.tableLoading = true
      this.$ajax.post('subGetewayQuery',{
        eid: eid,
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.tableLoading = false

        let subEids = []
        res.data.forEach(item=>{
            subEids.push(item.eid)
        })
        // if(flag==0) {
        //   this.reportEquimentDataQuery(subEids)
        //   flag = 1
        // }
        // if(flag==1) {
          this.reportEquimentDataQuery(subEids)
          this.timer = setInterval(()=>{
            this.reportEquimentDataQuery(subEids)
          },10000)
        // }
      }).catch(err=>{
        this.tableLoading = false
      })
    },
    // 获取设备上报数据
    reportEquimentDataQuery(subEids) {
        this.$ajax.post('reportEquimentDataQuery',{
        subEids: subEids,
      }).then(res => {
          this.equimentData = res.data
          this.initEchart()
      })
    },

    treeRowClick(data) {
      clearInterval(this.timer)
      this.deviceTypeCode = data.deviceTypeCode
      this.loadListData(this.row.eid)
    },
  },
  mounted() {
    if (this.$route.params.row == null) {
        this.$router.push('/iotEquipment')
    } else {
      this.row = this.$route.params.row
    }
    this.loadListData(this.row.eid)
  },
  destroyed() {
    clearInterval(this.timer)
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  margin-top: 20px;
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.echartLayout {
    display: flex;
    align-items: center;
    width: 100%;
    height: 415px;
    float: right;
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
}
.left-list {
    font-size: 14px;
    margin: 20px 20px;
    margin-top: 20px;
}
.left-list div {
    line-height: 57px;
    border-bottom: 1px solid #e8e8e8;
    padding-left: 20px;
}
.left-list div span {
    display: inline-block;
    width: 160px;
    font-weight: bold;
}

/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
</style>